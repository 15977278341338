<template>
	<ChooseQuestionQuery></ChooseQuestionQuery>
</template>

<script>
import ChooseQuestionQuery from "@/components/shared/tools/query/chooseQuestion/Index.vue";

export default {
	name: "ChooseQuestionQueryPage",

	components: {
		ChooseQuestionQuery,
	},
};
</script>

<style></style>
